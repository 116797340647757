'use client'

import { useEffect, useState } from 'react'
import { ContentContainer, EmbedContent } from './styles/content.style'
import Script from 'next/script'

export interface ContentBlockType {
  content: string
  html?: string
}

const Content = (props: {
  content: ContentBlockType
  editable?: boolean
  editAvailable?: () => void
}) => {
  const { editAvailable } = props
  let embedValue: any = !props.content ? 'Empty..' : props.content
  const [decodedHtml, setDecodedHtml] = useState(embedValue)
  const [hasBeenDecoded, setHasBeenDecoded] = useState(false)

  const onEditable = () => {
    if (!props.content) {
      if (editAvailable) editAvailable()
    }
  }

  useEffect(() => {
    function decodeHtml(encodedString: string) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(encodedString, 'text/html')
      const decodedContent = doc.body.innerHTML || ''

      return decodedContent
    }

    let blockContentPurified = embedValue.split('{CCM:BASE_URL}').join('')

    // legacy article blocks containe non-escaped <blockquote and <iframe tags, so we don't decode them
    if (embedValue.includes('<blockquote') || embedValue.includes('iframe')) {
      setDecodedHtml(blockContentPurified)
      return
    }

    if (!Array.isArray(blockContentPurified)) {
      setDecodedHtml(decodeHtml(blockContentPurified))
      setHasBeenDecoded(true)
    }
  }, [props.content])

  if (typeof window !== 'undefined' && hasBeenDecoded) {
    setTimeout(() => {
      window.instgrm?.Embeds?.process()
    }, 100)
  }

  return (
    <ContentContainer>
      <EmbedContent
        onClick={onEditable}
        isEmpty={!props.content}
        dangerouslySetInnerHTML={{ __html: decodedHtml }}
      />
      <Script
        id="instagram-embed"
        strategy="lazyOnload"
        src="https://www.instagram.com/embed.js"
      />
    </ContentContainer>
  )
}

export default Content
