'use client'

import { Fragment } from 'react'
import {
  QuoteBody,
  QuoteAuthor,
  Blockquote,
  QuoteBodyWithBackground,
  QuoteTitle,
  QuoteContent,
  QuotesImageContainer,
} from './styles/quote.style'
import { PROD_URL } from '@/constants'
import { Image } from '@/components/v2/atoms/image/image'
import { QuoteBlockType } from '@/components/v3/unique/article/blocks/quote/quote'

// @deprecated
const Quote = (props: { content: QuoteBlockType }) => {
  const template = props.content.template || 'default'
  const title = props.content.title || ''
  const oldTitleString = props.content.oldTitleString || ''
  let quote = props.content.quote || ''

  if (oldTitleString) {
    quote = oldTitleString + quote
  }

  if (template === 'light' || template === 'default') {
    return (
      <Blockquote>
        <Fragment>
          {title && <QuoteTitle isTitle>{title}</QuoteTitle>}
          <QuotesImageContainer>
            <Image
              src={`${PROD_URL}/assets/icons/double-quote-up.png`}
              alt={'Quote Up'}
              width={36}
              height={27}
            />
            <Image
              src={`${PROD_URL}/assets/icons/double-quote-down.png`}
              alt={'Quote Down'}
              width={35}
              height={25}
            />
          </QuotesImageContainer>
          <QuoteBody
            isEmpty={!props.content}
            dangerouslySetInnerHTML={{
              __html: quote,
            }}
          />
          <QuoteAuthor>{props.content.author}</QuoteAuthor>
        </Fragment>
      </Blockquote>
    )
  }

  return (
    <Blockquote>
      <Fragment>
        <QuoteBodyWithBackground isEmpty={!props.content}>
          {title && <QuoteTitle>{title}</QuoteTitle>}
          <QuoteContent
            dangerouslySetInnerHTML={{
              __html: quote,
            }}
          />
          <QuoteAuthor isDefault={false}>{props.content.author}</QuoteAuthor>
        </QuoteBodyWithBackground>
      </Fragment>
    </Blockquote>
  )
}

export default Quote
